import { graphql, useStaticQuery } from 'gatsby'

export interface PostType {
  id: string
  frontmatter: {
    title: string
    layout: string
    date: string
    tags: string[]
  }
  fields: {
    slug: string
  }
}

export type PostEdges = {
  node: PostType
}[]

export interface Posts {
  allMdx: {
    edges: PostEdges
  }
}

export const usePosts = (): PostEdges => {
  const data: Posts = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: {
          frontmatter: {
            layout: { eq: "post" }
            draft: { eq: false }
            hidden: { eq: false }
          }
        }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            frontmatter {
              layout
              title
              date(formatString: "MMM DD, YYYY")
              tags
            }
          }
        }
      }
    }
  `)
  return data.allMdx.edges
}
