import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Cell } from 'styled-css-grid'

import {
  Page,
  Container,
  Typography,
  SEO,
  Grid,
  Divider,
  Button,
  Link,
  PostList,
  ProjectCard,
  Tag,
} from '~/components'
import IndexLayout from '~/layouts'
import { SEOProps } from '~/components/SEO'
import { usePosts } from '~/hooks/usePosts'
import { useProjects } from '~/hooks/useProjects'

const StyledIndexPageWrapper = styled.div``

const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${(p) => p.theme.spacings[4]}rem;
`

const HiddenBr = styled.br`
  display: none;

  @media (min-width: ${(p) => p.theme.breakpoints.md}em) {
    display: block;
  }
`

const IndexPage = () => {
  const seo: SEOProps = {
    title: 'Home',
    path: '/',
    article: false,
  }

  const posts = usePosts()
  const projects = useProjects()
  const theme = useContext(ThemeContext)

  return (
    <IndexLayout>
      <SEO
        title={seo.title}
        description={seo.description}
        image={seo.image}
        path={seo.path}
        article={seo.article}
      />
      <Page>
        <StyledIndexPageWrapper>
          <Container>
            <Typography variant="h1" marginBottom={2}>
              Bryan Berger
            </Typography>
            <Typography variant="h3" color="gray300">
              I make stuff with design &amp; code, Sr. Product Designer at{' '}
              <Link showExternal={false} to="https://discord.com">
                Discord
              </Link>
            </Typography>
          </Container>
          <Container>
            <Divider />
            <StyledFlex>
              <Typography variant="h2" marginBottom={0}>
                Latest Posts
              </Typography>
              <Button as={Link} to="/posts" variant="primarySm">
                All Posts
              </Button>
            </StyledFlex>
            <PostList posts={posts} numPosts={8} />
            <Divider />
            <StyledFlex>
              <Typography variant="h2" marginBottom={0}>
                Select Work
              </Typography>
              <Button as={Link} to="/projects" variant="primarySm">
                All Work
              </Button>
            </StyledFlex>
            <Grid
              columns="repeat(auto-fit, minmax(327px, 1fr))"
              rowGap={`${theme.grid.gapLg}px`}
            >
              {projects.slice(0, 12).map(({ node }) => {
                const { slug } = node.fields
                const {
                  date,
                  dateRangeStr,
                  title,
                  thumbnail,
                  tags,
                } = node.frontmatter
                return (
                  <Cell key={slug}>
                    <ProjectCard
                      title={title}
                      slug={slug}
                      date={dateRangeStr || date}
                      image={thumbnail}
                      tags={
                        <>
                          {tags &&
                            tags.map((tag) => (
                              <Tag key={tag} to={tag}>
                                {tag}
                              </Tag>
                            ))}
                        </>
                      }
                    />
                  </Cell>
                )
              })}
            </Grid>
          </Container>
        </StyledIndexPageWrapper>
      </Page>
    </IndexLayout>
  )
}

export default IndexPage
